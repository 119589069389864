import { RefObject } from "react";
import { IDimension } from "./DimensionLine";
import { IElementSize } from "../../../../interfaces/DTO/IElementSize";
import {
  CONTROL_ICON_SIZES,
  FUNCTIONAL_ICON_CONDITIONS,
  FUNCTIONAL_SMALL_ICON_SIZES,
} from "../../../../constants/ItemsDimensions";
import { EInsertDetailItemType, IInsertDetailItem } from "../../../../interfaces/IInsertDetail";
import { IInsertType } from "../../../../interfaces/IInsertType";

export const ONE_PIXEL_IN_MILLITER = 0.2645833333;

export const getElementPosition = (
  coverRef: RefObject<HTMLDivElement>,
  elementRef: React.RefObject<HTMLDivElement | HTMLCanvasElement>,
  getElementSize: () => IElementSize,
  elementSizeProps?: IElementSize
) => {
  if (coverRef && elementRef) {
    const coverPositions = coverRef.current?.getBoundingClientRect();
    const elementPositions = elementRef.current?.getBoundingClientRect();

    const elementSize = elementSizeProps ? elementSizeProps : getElementSize();
    const widthpx = elementSize.width;
    const heightpx = elementSize.height;
    const widthmm = elementSize.width! * ONE_PIXEL_IN_MILLITER;
    const heightmm = elementSize.height! * ONE_PIXEL_IN_MILLITER;

    const coverLeft = coverPositions?.left;
    const elementLeft = elementPositions?.left;

    const coverBottom = coverPositions?.bottom;
    const elementBottom = elementPositions?.bottom;

    let elementXpx = Math.abs(coverLeft! - elementLeft!) + widthpx! / 2; //left + half of element width
    let elementYpx = Math.abs(coverBottom! - elementBottom!) + heightpx! / 2; //bottom + half of element height

    const defaultOrientation = elementRef.current?.getAttribute("data-default-orientation");
    const currentOrientation = elementRef.current?.getAttribute("data-orientation");
    if (currentOrientation != defaultOrientation) {
      elementXpx = Math.abs(coverLeft! - elementLeft!) + heightpx! / 2; //left + half of element heightpx
      elementYpx = Math.abs(coverBottom! - elementBottom!) + widthpx! / 2; //bottom + half of element widthpx
    }

    const elementXmm = elementXpx * ONE_PIXEL_IN_MILLITER;
    const elementYmm = elementYpx * ONE_PIXEL_IN_MILLITER;

    //vertical line
    let startPositionXMm = elementXmm;
    let startPositionYMm = elementYmm - heightmm / 2;
    let endPositionXMm = elementXmm;
    let endPositionYMm = elementYmm + heightmm / 2;

    // horizontal line
    if (currentOrientation == "horizontal") {
      startPositionXMm = elementXmm - widthmm / 2;
      startPositionYMm = elementYmm;
      endPositionXMm = elementXmm + widthmm / 2;
      endPositionYMm = elementYmm;
    }

    if (currentOrientation == "horizontal" && defaultOrientation == "vertical") {
      startPositionXMm = elementXmm - heightmm / 2;
      startPositionYMm = elementYmm;
      endPositionXMm = elementXmm + heightmm / 2;
      endPositionYMm = elementYmm;
    }

    if (currentOrientation == "vertical" && defaultOrientation == "horizontal") {
      startPositionXMm = elementXmm;
      startPositionYMm = elementYmm - widthmm / 2;
      endPositionXMm = elementXmm;
      endPositionYMm = elementYmm + widthmm / 2;
    }

    //POC - force dimensions
    const designCode = coverRef.current?.getAttribute("data-designcode");
    let _xmm = elementXmm;
    let _ymm = elementYmm;

    switch (designCode) {
      case "buschbalancesi":
        _xmm = _xmm - 0.2;
        _ymm = _ymm + 0.25;
        break;

      case "skyniessen":
        _xmm = _xmm - 0.4;
        _ymm = _ymm + 0.1;
        break;

      case "carat":
      case "future":
      // case "sidus":
      case "calle":
      case "purestainlesssteel":
      case "axcentpur":
      case "futurelinear":
      case "solo":
      case "axcent":
      case "dynasty":
        _xmm = _xmm - 0.25;
        _ymm = _ymm + 0.25;
        break;

      default:
        break;
    }

    return {
      xpx: Math.round(elementXpx),
      ypx: Math.round(elementYpx),
      xmm: _xmm,
      ymm: _ymm,
      widthpx: widthpx,
      heightpx: heightpx,
      widthmm: widthmm,
      heightmm: heightmm,
      startPositionXMm: startPositionXMm,
      startPositionYMm: startPositionYMm,
      endPositionXMm: endPositionXMm,
      endPositionYMm: endPositionYMm,
    } as IDimension;
  }
};

export const getElementSize = (ref: RefObject<any>) => {
  return () => {
    const elementSize: IElementSize = {
      width: ref.current?.offsetWidth,
      height: ref.current?.offsetHeight,
    };

    return elementSize;
  };
};

export const generateCoverHash = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getMatchedPercentSize = (item: IInsertDetailItem, insertType: IInsertType) => {
  const isFunctionalIcon = FUNCTIONAL_ICON_CONDITIONS.some(
    (condition) => condition.name === insertType?.name && String(item.indexDetail) === condition.index
  );
  const sizeList = isFunctionalIcon ? FUNCTIONAL_SMALL_ICON_SIZES : CONTROL_ICON_SIZES;
  return Object.keys(sizeList).find((key) => item.dimension?.height === sizeList[Number(key)]);
};

export const checkCanvasStatus = (items: IInsertDetailItem[], index: string) => {
  const hasText = items?.some(
    (item) => item.type === EInsertDetailItemType.Text && item.indexDetail.toString() === index
  );
  const hasIcon = items?.some(
    (item) => item.type === EInsertDetailItemType.Icon && item.indexDetail.toString() === index
  );
  if (hasIcon && !hasText) {
    return "-only-icon";
  } else if (hasIcon && hasText) {
    return "-with-icon-and-canvas";
  } else if (hasText) {
    return "-only-canvas";
  }
  return "";
};
