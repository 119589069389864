import { Grid } from "@mui/material";
import TileConfiguration from "../TileConfiguration";
import StandardTile from "../StandardTile";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { isHorizontal, LimitChar } from "../limitChar";
import { useRef } from "react";
import { EInsertType } from "../../../../../enum/EInsertType";
import SliderLine from "../../../Components/SliderLine/SliderLine";
import { checkCanvasStatus } from "../utils";
import { ELEMENTS } from "../../../../../constants/ItemsDimensions";

const OneSlider: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.SMALL,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.SMALL,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: 4,
    isFunction: false,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);
  const sliderTopRef = useRef<HTMLHRElement>(null);
  const sliderBottomRef = useRef<HTMLHRElement>(null);

  const canvasIndexDetail = ELEMENTS.ONE;
  return (
    <>
      <div
        ref={coverRef}
        data-designcode={props.designCode}
      >
        <StandardTile designCode={props.designCode}>
          <Grid
            className={`${border}`}
            container
            item
            justifyContent="center"
            alignItems="center"
          >
            <SliderLine
              coverRef={coverRef}
              elementRef={sliderTopRef}
              className={`slider slider-top${checkCanvasStatus(props.insertDetail.items, canvasIndexDetail)}`}
              indexDetail="2"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneSlider
                      : textFieldProps.vertical.oneSlider
                  }
                  tileName="oneSlider"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail={canvasIndexDetail}
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  tileType={EInsertType.Slider}
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.oneSlider
                      : textFieldProps.vertical.oneSlider
                  }
                  tileName="oneSlider"
                  tileDimension={tileDimension}
                  insertDetail={props.insertDetail!}
                  setInsertDetail={props.setInsertDetail!}
                  cover={props.cover}
                  indexDetail={canvasIndexDetail}
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
            <SliderLine
              coverRef={coverRef}
              elementRef={sliderBottomRef}
              className={`slider slider-bottom${checkCanvasStatus(props.insertDetail.items, canvasIndexDetail)}`}
              indexDetail="3"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default OneSlider;
