import { t } from "i18next";
import React from "react";
import "./IconDialog.css";
import "./TextAreaControlled.css";
import { useTheme } from "@mui/material";
import { FTSTheme } from "../../../App";
import { ABB } from "../../../constants/Theme";
import { IDisplayHighlightedWarning, IHandleTextInputChangeParams } from "../../../interfaces/ITextAreaControlled";
import { MAX_CHARS_NUMBER } from "../../../constants/NumberValues";

interface IProps {
  fontSize: number;
  textAreaValue: string;
  displayHighlightedWarning: IDisplayHighlightedWarning;
  handleTextInputChange: (params: IHandleTextInputChangeParams) => void;
}

const TextAreaControlled: React.FC<IProps> = ({ handleTextInputChange, textAreaValue, displayHighlightedWarning }) => {
  const ftsTheme = useTheme() as FTSTheme;

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;

    if (inputValue.length > MAX_CHARS_NUMBER) {
      return;
    }

    const emojiUnicodes =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
    const noEmoji = inputValue.replace(emojiUnicodes, "");

    const textInputChangeArgs: IHandleTextInputChangeParams = {
      value: noEmoji,
      startPosition: event.target.selectionStart,
      endPosition: event.target.selectionEnd,
    };
    handleTextInputChange(textInputChangeArgs);
  };

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    event.target.placeholder = "";
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    event.target.placeholder = t("InsertConfiguration_IconDialog_TextareaPlaceholder");
  };

  return (
    <div className="textarea-wrapper">
      <textarea
        style={{ fontFamily: `${ftsTheme.name === ABB ? "ABBVoice" : "BJEAverta"}` }}
        className="textarea"
        value={textAreaValue}
        placeholder={t("InsertConfiguration_IconDialog_TextareaPlaceholder")}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
      />
      {displayHighlightedWarning.display && textAreaValue.length > 0 && (
        <div
          className="div-textarea-highlight"
          style={{ fontFamily: `${ftsTheme.name === ABB ? "ABBVoice" : "BJEAverta"}` }}
        >
          <span>{textAreaValue.substring(0, displayHighlightedWarning.start)}</span>
          <mark>{textAreaValue.substring(displayHighlightedWarning.start, textAreaValue.length)}</mark>
        </div>
      )}
    </div>
  );
};

export default TextAreaControlled;
