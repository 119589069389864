import { EInsertDetailItemType, IInsertDetail } from "../../../../interfaces/IInsertDetail";
import {
  BUTTON,
  FOUR_BUTTONS,
  ONE_BUTTON_ONE_ROCKER,
  ONE_SLIDER_ONE_BUTTON,
  ONE_SLIDER_ONE_ROCKER,
  ONE_SLIDER_TWO_BUTTONS,
  ROCKER,
  SLIDER,
  TWO_BUTTONS,
  TWO_BUTTONS_ONE_ROCKER,
  TWO_ROCKERS,
  TWO_SLIDERS,
} from "../../../../constants/InsertType";
import {
  BOTTOM_LINE,
  CONTROL_ICON_SIZES,
  ELEMENTS,
  FUNCTIONAL_ICON_CONDITIONS,
  FUNCTIONAL_SMALL_ICON_SIZES,
  ICON,
  LAYOUTS,
  POSITIONS,
  SEPARATION_HALF_LINE,
  SEPARATION_LINE,
  SEPARATION_LINE_HORIZONTAL,
  SEPARATION_LINE_VERTICAL,
  TEXT,
  TEXT_SIZES,
  TOP_LINE,
  WITH_TEXT,
  WITHOUT_TEXT,
} from "../../../../constants/ItemsDimensions";
import { IDesignDimensions } from "../../../../interfaces/IDesignDimensions";
import { EFontPixelSize } from "../../../../enum/EFontSize";
import {
  NO_ROTATION_0_DEGREE,
  ROTATION_180_DEGREE,
  ROTATION_270_DEGREE,
  ROTATION_90_DEGREE,
} from "../../../../constants/NumberValues";

type Dimensions = {
  actualWidthMm: number;
  actualHeightMm: number;
  actualPosXMm?: number;
  actualPosYMm?: number;
  actualStartXMm?: number;
  actualStartYMm?: number;
  actualEndXMm?: number;
  actualEndYMm?: number;
};

const getSafeValue = (designSizes: IDesignDimensions, path: string[], defaultValue: number = 0) => {
  return path.reduce((acc: any, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue), designSizes);
};

const getDimensions = (designSizes: IDesignDimensions, keys: string[]) => ({
  actualWidthMm: getSafeValue(designSizes, [...keys, "width"]),
  actualHeightMm: getSafeValue(designSizes, [...keys, "height"]),
  actualPosXMm: getSafeValue(designSizes, [...keys, "posX"]),
  actualPosYMm: getSafeValue(designSizes, [...keys, "posY"]),
});

const getButtonDimensions = (
  designSizes: IDesignDimensions,
  type: EInsertDetailItemType,
  keyLayoutValue: string | string[]
) => {
  const iconOrText = type === EInsertDetailItemType.Icon ? ICON : TEXT;
  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);
  const fullPath = [...resolveKeyPath(keyLayoutValue), iconOrText];
  return getDimensions(designSizes, fullPath);
};

const getSeparationLineDimensions = (designSizes: IDesignDimensions, keyLayoutValue: string | string[]) => {
  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);

  const fullPath = [...resolveKeyPath(keyLayoutValue)];

  const lineData = getSafeValue(designSizes, fullPath);

  return lineData
    ? {
        actualStartXMm: lineData.startX || 0,
        actualStartYMm: lineData.startY || 0,
        actualEndXMm: lineData.endX || 0,
        actualEndYMm: lineData.endY || 0,
        actualWidthMm: lineData.width || 0,
        actualHeightMm: lineData.height || 0,
      }
    : initializeDimensions();
};

const getSliderDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string,
  indexArray: string[],
  keyLayoutValue: string | string[]
): Dimensions => {
  const withText =
    type === EInsertDetailItemType.Text ||
    insertDetail.items.some((item, _, items) =>
      items.some(
        (otherItem) =>
          Number(otherItem.indexDetail) === Number(item.indexDetail) &&
          ((item.type === EInsertDetailItemType.Text && otherItem.type === EInsertDetailItemType.Icon) ||
            (item.type === EInsertDetailItemType.Icon && otherItem.type === EInsertDetailItemType.Text))
      )
    );
  const key = withText ? WITH_TEXT : WITHOUT_TEXT;
  const iconOrText = type === EInsertDetailItemType.Icon ? ICON : TEXT;

  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);

  if (indexDetail === indexArray[0]) {
    const fullPath = [...resolveKeyPath(keyLayoutValue), key, iconOrText];
    return {
      ...getDimensions(designSizes, fullPath),
      actualStartXMm: 0,
      actualStartYMm: 0,
      actualEndXMm: 0,
      actualEndYMm: 0,
    };
  }

  const lineData =
    indexDetail === indexArray[1]
      ? getSafeValue(designSizes, [...resolveKeyPath(keyLayoutValue), key, TOP_LINE])
      : indexDetail === indexArray[2]
        ? getSafeValue(designSizes, [...resolveKeyPath(keyLayoutValue), key, BOTTOM_LINE])
        : null;

  return lineData
    ? {
        actualStartXMm: lineData.startX || 0,
        actualStartYMm: lineData.startY || 0,
        actualEndXMm: lineData.endX || 0,
        actualEndYMm: lineData.endY || 0,
        actualWidthMm: lineData.width || 0,
        actualHeightMm: lineData.height || 0,
      }
    : initializeDimensions();
};

const getRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string,
  indexArray: string[],
  keyLayoutValue: string | string[]
): Dimensions => {
  const verticalPositionMap: Record<string, string> = {
    [indexArray[0]]: insertDetail.rotate === ROTATION_180_DEGREE ? POSITIONS.BOTTOM : POSITIONS.TOP,
    [indexArray[1]]: POSITIONS.MIDDLE,
    [indexArray[2]]: insertDetail.rotate === ROTATION_180_DEGREE ? POSITIONS.TOP : POSITIONS.BOTTOM,
  };

  const horizontalPositionMap: Record<string, string> = {
    [indexArray[0]]: insertDetail.rotate === ROTATION_270_DEGREE ? POSITIONS.LEFT : POSITIONS.RIGHT,
    [indexArray[1]]: POSITIONS.MIDDLE,
    [indexArray[2]]: insertDetail.rotate === ROTATION_270_DEGREE ? POSITIONS.RIGHT : POSITIONS.LEFT,
  };

  const resolveKeyPath = (key: string | string[]) => (Array.isArray(key) ? key : [key]);

  let position =
    insertDetail.rotate === NO_ROTATION_0_DEGREE || insertDetail.rotate === ROTATION_180_DEGREE
      ? verticalPositionMap[indexDetail]
      : horizontalPositionMap[indexDetail];

  if (insertDetail?.insertType?.name === TWO_BUTTONS_ONE_ROCKER) {
    position =
      insertDetail.rotate === ROTATION_90_DEGREE || insertDetail.rotate === ROTATION_270_DEGREE
        ? verticalPositionMap[indexDetail]
        : horizontalPositionMap[indexDetail];
  }

  if (position) {
    const iconOrText = type === EInsertDetailItemType.Icon ? ICON : TEXT;
    const fullPath = [...resolveKeyPath(keyLayoutValue), `${position}_${iconOrText}`];
    return {
      ...getDimensions(designSizes, fullPath),
      actualStartXMm: 0,
      actualStartYMm: 0,
      actualEndXMm: 0,
      actualEndYMm: 0,
    };
  }

  return initializeDimensions();
};

const getSliderRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
) => {
  const isFlipped = insertDetail.isFlipped;

  const sliderIndexArray = [ELEMENTS.ONE, ELEMENTS.SIX, ELEMENTS.SEVEN];
  const rockerIndexArray = [ELEMENTS.TWO, ELEMENTS.THREE, ELEMENTS.FOUR];

  let dimensions;
  if (sliderIndexArray.includes(indexDetail)) {
    dimensions = getSliderDimensions(designSizes, insertDetail, type, indexDetail, sliderIndexArray, [
      isFlipped ? LAYOUTS.ROCKER_SLIDER : LAYOUTS.SLIDER_ROCKER,
      LAYOUTS.SLIDER,
    ]);
  } else if (rockerIndexArray.includes(indexDetail)) {
    dimensions = getRockerDimensions(designSizes, insertDetail, type, indexDetail, rockerIndexArray, [
      isFlipped ? LAYOUTS.ROCKER_SLIDER : LAYOUTS.SLIDER_ROCKER,
      LAYOUTS.ROCKER,
    ]);
  } else {
    dimensions = getSeparationLineDimensions(designSizes, [
      isFlipped ? LAYOUTS.ROCKER_SLIDER : LAYOUTS.SLIDER_ROCKER,
      SEPARATION_LINE,
    ]);
  }

  const hasNonZeroValues = dimensions && Object.values(dimensions).some((value) => value !== 0);
  return hasNonZeroValues ? dimensions : initializeDimensions();
};

const getSliderSliderDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
) => {
  const isFlipped = insertDetail.isFlipped;
  const elementsGroups = {
    left: [ELEMENTS.ONE, ELEMENTS.THREE, ELEMENTS.FOUR],
    right: [ELEMENTS.TWO, ELEMENTS.FIVE, ELEMENTS.SIX],
  };

  const [sliderLeftIndexArray, sliderRightIndexArray] = isFlipped
    ? [elementsGroups.right, elementsGroups.left]
    : [elementsGroups.left, elementsGroups.right];

  let dimensions;
  if (sliderLeftIndexArray.includes(indexDetail)) {
    dimensions = getSliderDimensions(designSizes, insertDetail, type, indexDetail, sliderLeftIndexArray, [
      LAYOUTS.SLIDER_SLIDER,
      POSITIONS.SLIDER_LEFT,
    ]);
  } else if (sliderRightIndexArray.includes(indexDetail)) {
    dimensions = getSliderDimensions(designSizes, insertDetail, type, indexDetail, sliderRightIndexArray, [
      LAYOUTS.SLIDER_SLIDER,
      POSITIONS.SLIDER_RIGHT,
    ]);
  } else {
    dimensions = getSeparationLineDimensions(designSizes, [LAYOUTS.SLIDER_SLIDER, SEPARATION_LINE]);
  }

  const hasNonZeroValues = dimensions && Object.values(dimensions).some((value) => value !== 0);
  return hasNonZeroValues ? dimensions : initializeDimensions();
};

const getSliderButtonDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
) => {
  const isFlipped = insertDetail.isFlipped;

  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [
      isFlipped ? LAYOUTS.BUTTON_SLIDER : LAYOUTS.SLIDER_BUTTON,
      SEPARATION_LINE,
    ]);
  } else if (
    type === EInsertDetailItemType.Slider ||
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.ONE) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.ONE)
  ) {
    return getSliderDimensions(
      designSizes,
      insertDetail,
      type,
      indexDetail,
      [ELEMENTS.ONE, ELEMENTS.THREE, ELEMENTS.FOUR],
      [isFlipped ? LAYOUTS.BUTTON_SLIDER : LAYOUTS.SLIDER_BUTTON, LAYOUTS.SLIDER]
    );
  } else if (
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.TWO) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.TWO)
  ) {
    return getButtonDimensions(designSizes, type, [
      isFlipped ? LAYOUTS.BUTTON_SLIDER : LAYOUTS.SLIDER_BUTTON,
      LAYOUTS.BUTTON,
    ]);
  }

  return initializeDimensions();
};

const getButtonButtonDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const isVertical = insertDetail.rotate === NO_ROTATION_0_DEGREE || insertDetail.rotate === ROTATION_180_DEGREE;
  const position = isVertical ? POSITIONS.VERTICAL : POSITIONS.HORIZONTAL;

  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [LAYOUTS.BUTTON_BUTTON, position, SEPARATION_LINE]);
  }

  let buttonPosition;
  if (indexDetail === ELEMENTS.ONE) {
    if (isVertical) {
      buttonPosition = insertDetail.rotate === NO_ROTATION_0_DEGREE ? POSITIONS.BUTTON_LEFT : POSITIONS.BUTTON_RIGHT;
    } else {
      buttonPosition = insertDetail.rotate === ROTATION_90_DEGREE ? POSITIONS.BUTTON_TOP : POSITIONS.BUTTON_BOTTOM;
    }
  } else if (indexDetail === ELEMENTS.TWO) {
    if (isVertical) {
      buttonPosition = insertDetail.rotate === NO_ROTATION_0_DEGREE ? POSITIONS.BUTTON_RIGHT : POSITIONS.BUTTON_LEFT;
    } else {
      buttonPosition = insertDetail.rotate === ROTATION_90_DEGREE ? POSITIONS.BUTTON_BOTTOM : POSITIONS.BUTTON_TOP;
    }
  }

  const buttonDimensions = getButtonDimensions(designSizes, type, [LAYOUTS.BUTTON_BUTTON, position, buttonPosition]);

  return buttonDimensions || initializeDimensions();
};

const getRockerRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const isVertical = insertDetail.rotate === NO_ROTATION_0_DEGREE || insertDetail.rotate === ROTATION_180_DEGREE;
  const position = isVertical ? POSITIONS.VERTICAL : POSITIONS.HORIZONTAL;

  const rockerLeftIndexArray = [ELEMENTS.ONE, ELEMENTS.THREE, ELEMENTS.FIVE];
  const rockerRightIndexArray = [ELEMENTS.TWO, ELEMENTS.FOUR, ELEMENTS.SIX];

  let dimensions;
  if (rockerLeftIndexArray.includes(indexDetail)) {
    dimensions = getRockerDimensions(designSizes, insertDetail, type, indexDetail, rockerLeftIndexArray, [
      LAYOUTS.ROCKER_ROCKER,
      position,
      insertDetail.rotate === NO_ROTATION_0_DEGREE
        ? POSITIONS.ROCKER_LEFT
        : isVertical
          ? POSITIONS.ROCKER_RIGHT
          : insertDetail.rotate === ROTATION_90_DEGREE
            ? POSITIONS.ROCKER_TOP
            : POSITIONS.ROCKER_BOTTOM,
    ]);
  } else if (rockerRightIndexArray.includes(indexDetail)) {
    dimensions = getRockerDimensions(designSizes, insertDetail, type, indexDetail, rockerRightIndexArray, [
      LAYOUTS.ROCKER_ROCKER,
      position,
      insertDetail.rotate === NO_ROTATION_0_DEGREE
        ? POSITIONS.ROCKER_RIGHT
        : isVertical
          ? POSITIONS.ROCKER_LEFT
          : insertDetail.rotate === ROTATION_90_DEGREE
            ? POSITIONS.ROCKER_BOTTOM
            : POSITIONS.ROCKER_TOP,
    ]);
  } else {
    dimensions = getSeparationLineDimensions(designSizes, [LAYOUTS.ROCKER_ROCKER, position, SEPARATION_LINE]);
  }

  const hasNonZeroValues = dimensions && Object.values(dimensions).some((value) => value !== 0);
  return hasNonZeroValues ? dimensions : initializeDimensions();
};

const getButtonRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const leftButtonRightRocker = insertDetail.rotate === NO_ROTATION_0_DEGREE;
  const topButtonBottomRocker = insertDetail.rotate === ROTATION_90_DEGREE;
  const rightButtonLeftRocker = insertDetail.rotate === ROTATION_180_DEGREE;
  const topRockerBottomButton = insertDetail.rotate === ROTATION_270_DEGREE;

  if (type === EInsertDetailItemType.SeparationLine) {
    let path: string[];
    if (leftButtonRightRocker) {
      path = [LAYOUTS.BUTTON_ROCKER, POSITIONS.VERTICAL, SEPARATION_LINE];
    } else if (topButtonBottomRocker) {
      path = [LAYOUTS.BUTTON_ROCKER, POSITIONS.HORIZONTAL, SEPARATION_LINE];
    } else if (rightButtonLeftRocker) {
      path = [LAYOUTS.ROCKER_BUTTON, POSITIONS.VERTICAL, SEPARATION_LINE];
    } else {
      path = [LAYOUTS.ROCKER_BUTTON, POSITIONS.HORIZONTAL, SEPARATION_LINE];
    }

    return getSeparationLineDimensions(designSizes, path);
  }

  const buttonDimensions =
    indexDetail === ELEMENTS.FOUR &&
    getButtonDimensions(
      designSizes,
      type,
      leftButtonRightRocker
        ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.VERTICAL, POSITIONS.BUTTON_LEFT]
        : topButtonBottomRocker
          ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.HORIZONTAL, POSITIONS.BUTTON_TOP]
          : rightButtonLeftRocker
            ? [LAYOUTS.ROCKER_BUTTON, POSITIONS.VERTICAL, POSITIONS.BUTTON_RIGHT]
            : [LAYOUTS.ROCKER_BUTTON, POSITIONS.HORIZONTAL, POSITIONS.BUTTON_BOTTOM]
    );

  const rockerDimensions = getRockerDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    [ELEMENTS.ONE, ELEMENTS.TWO, ELEMENTS.THREE],
    leftButtonRightRocker
      ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.VERTICAL, POSITIONS.ROCKER_RIGHT]
      : topButtonBottomRocker
        ? [LAYOUTS.BUTTON_ROCKER, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_BOTTOM]
        : rightButtonLeftRocker
          ? [LAYOUTS.ROCKER_BUTTON, POSITIONS.VERTICAL, POSITIONS.ROCKER_LEFT]
          : [LAYOUTS.ROCKER_BUTTON, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_TOP]
  );

  const hasNonZeroButton = Object.values(buttonDimensions).some((value) => value !== 0);
  const hasNonZeroRocker = Object.values(rockerDimensions).some((value) => value !== 0);

  if (hasNonZeroButton) {
    return buttonDimensions || initializeDimensions();
  } else if (hasNonZeroRocker) {
    return rockerDimensions;
  }

  return initializeDimensions();
};

const getSliderButtonsDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const isFlipped = insertDetail.isFlipped;

  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [
      isFlipped ? LAYOUTS.BUTTONS_SLIDER : LAYOUTS.SLIDER_BUTTONS,
      indexDetail === ELEMENTS.SIX ? SEPARATION_LINE : SEPARATION_HALF_LINE,
    ]);
  } else if (
    type === EInsertDetailItemType.Slider ||
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.ONE) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.ONE)
  ) {
    return getSliderDimensions(
      designSizes,
      insertDetail,
      type,
      indexDetail,
      [ELEMENTS.ONE, ELEMENTS.FOUR, ELEMENTS.FIVE],
      [isFlipped ? LAYOUTS.BUTTONS_SLIDER : LAYOUTS.SLIDER_BUTTONS, LAYOUTS.SLIDER]
    );
  } else if (
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.TWO) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.TWO) ||
    (type === EInsertDetailItemType.Icon && indexDetail === ELEMENTS.THREE) ||
    (type === EInsertDetailItemType.Text && indexDetail === ELEMENTS.THREE)
  ) {
    return getButtonDimensions(designSizes, type, [
      isFlipped ? LAYOUTS.BUTTONS_SLIDER : LAYOUTS.SLIDER_BUTTONS,
      LAYOUTS.BUTTONS,
      indexDetail === ELEMENTS.TWO ? POSITIONS.BUTTON_TOP : POSITIONS.BUTTON_BOTTOM,
    ]);
  }

  return initializeDimensions();
};

const getButtonsRockerDimensions = (
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  const topButtonsBottomRocker = insertDetail.rotate === NO_ROTATION_0_DEGREE;
  const leftRockerRightButtons = insertDetail.rotate === ROTATION_90_DEGREE;
  const topRockerBottomButtons = insertDetail.rotate === ROTATION_180_DEGREE;
  const rightRockerLeftButtons = insertDetail.rotate === ROTATION_270_DEGREE;

  if (type === EInsertDetailItemType.SeparationLine) {
    let path;
    if (topButtonsBottomRocker) {
      path = [
        LAYOUTS.BUTTONS_ROCKER,
        POSITIONS.HORIZONTAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    } else if (leftRockerRightButtons) {
      path = [
        LAYOUTS.ROCKER_BUTTONS,
        POSITIONS.VERTICAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    } else if (topRockerBottomButtons) {
      path = [
        LAYOUTS.ROCKER_BUTTONS,
        POSITIONS.HORIZONTAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    } else {
      path = [
        LAYOUTS.BUTTONS_ROCKER,
        POSITIONS.VERTICAL,
        indexDetail === ELEMENTS.SIX ? SEPARATION_HALF_LINE : SEPARATION_LINE,
      ];
    }
    return getSeparationLineDimensions(designSizes, path);
  }

  const buttonDimensions = getButtonDimensions(
    designSizes,
    type,
    topButtonsBottomRocker
      ? [
          LAYOUTS.BUTTONS_ROCKER,
          POSITIONS.HORIZONTAL,
          POSITIONS.BUTTONS_TOP,
          indexDetail === ELEMENTS.ONE ? POSITIONS.BUTTON_LEFT : POSITIONS.BUTTON_RIGHT,
        ]
      : leftRockerRightButtons
        ? [
            LAYOUTS.ROCKER_BUTTONS,
            POSITIONS.VERTICAL,
            POSITIONS.BUTTONS_RIGHT,
            indexDetail === ELEMENTS.ONE ? POSITIONS.BUTTON_TOP : POSITIONS.BUTTON_BOTTOM,
          ]
        : topRockerBottomButtons
          ? [
              LAYOUTS.ROCKER_BUTTONS,
              POSITIONS.HORIZONTAL,
              POSITIONS.BUTTONS_BOTTOM,
              indexDetail === ELEMENTS.TWO ? POSITIONS.BUTTON_LEFT : POSITIONS.BUTTON_RIGHT,
            ]
          : [
              LAYOUTS.BUTTONS_ROCKER,
              POSITIONS.VERTICAL,
              POSITIONS.BUTTONS_LEFT,
              indexDetail === ELEMENTS.TWO ? POSITIONS.BUTTON_TOP : POSITIONS.BUTTON_BOTTOM,
            ]
  );

  const rockerDimensions = getRockerDimensions(
    designSizes,
    insertDetail,
    type,
    indexDetail,
    topButtonsBottomRocker || rightRockerLeftButtons
      ? [ELEMENTS.FIVE, ELEMENTS.FOUR, ELEMENTS.THREE]
      : [ELEMENTS.THREE, ELEMENTS.FOUR, ELEMENTS.FIVE],
    topButtonsBottomRocker
      ? [LAYOUTS.BUTTONS_ROCKER, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_BOTTOM]
      : leftRockerRightButtons
        ? [LAYOUTS.ROCKER_BUTTONS, POSITIONS.VERTICAL, POSITIONS.ROCKER_LEFT]
        : topRockerBottomButtons
          ? [LAYOUTS.ROCKER_BUTTONS, POSITIONS.HORIZONTAL, POSITIONS.ROCKER_TOP]
          : [LAYOUTS.BUTTONS_ROCKER, POSITIONS.VERTICAL, POSITIONS.ROCKER_RIGHT]
  );

  const hasNonZeroButton = Object.values(buttonDimensions).some((value) => value !== 0);
  const hasNonZeroRocker = Object.values(rockerDimensions).some((value) => value !== 0);

  if (hasNonZeroButton && !hasNonZeroRocker) {
    return buttonDimensions;
  } else if (hasNonZeroRocker) {
    return rockerDimensions;
  }

  return initializeDimensions();
};

const getFourButtonsDimensions = (
  designSizes: IDesignDimensions,
  type: EInsertDetailItemType,
  indexDetail: string
): Dimensions => {
  if (type === EInsertDetailItemType.SeparationLine) {
    return getSeparationLineDimensions(designSizes, [
      LAYOUTS.FOUR_BUTTONS,
      indexDetail === ELEMENTS.FIVE ? SEPARATION_LINE_VERTICAL : SEPARATION_LINE_HORIZONTAL,
    ]);
  }

  const buttonPositions = {
    [ELEMENTS.ONE]: POSITIONS.TOP_LEFT,
    [ELEMENTS.TWO]: POSITIONS.TOP_RIGHT,
    [ELEMENTS.THREE]: POSITIONS.BOTTOM_LEFT,
    [ELEMENTS.FOUR]: POSITIONS.BOTTOM_RIGHT,
  };

  const buttonDimensions = getButtonDimensions(designSizes, type, [LAYOUTS.FOUR_BUTTONS, buttonPositions[indexDetail]]);

  return buttonDimensions || initializeDimensions();
};

const initializeDimensions = () => ({
  actualWidthMm: 0,
  actualHeightMm: 0,
  actualPosXMm: 0,
  actualPosYMm: 0,
  actualStartXMm: 0,
  actualStartYMm: 0,
  actualEndXMm: 0,
  actualEndYMm: 0,
});

export const getItemsDimensions = (
  indexDetail: string,
  layoutName: string | undefined,
  designSizes: IDesignDimensions,
  insertDetail: IInsertDetail,
  type: EInsertDetailItemType,
  iconSizePercentage: number,
  fontSize: number
) => {
  if (!designSizes) {
    console.warn("designSizes data is undefined");
    return;
  }

  let dimensions: Dimensions = initializeDimensions();

  switch (layoutName) {
    case BUTTON:
      dimensions = getButtonDimensions(designSizes, type, LAYOUTS.BUTTON);
      break;
    case SLIDER:
      dimensions = getSliderDimensions(
        designSizes,
        insertDetail,
        type,
        indexDetail,
        [ELEMENTS.ONE, ELEMENTS.TWO, ELEMENTS.THREE],
        LAYOUTS.SLIDER
      );
      break;
    case ROCKER:
      const isVertical = insertDetail.rotate === NO_ROTATION_0_DEGREE || insertDetail.rotate === ROTATION_180_DEGREE;
      dimensions = getRockerDimensions(
        designSizes,
        insertDetail,
        type,
        indexDetail,
        [ELEMENTS.ONE, ELEMENTS.TWO, ELEMENTS.THREE],
        isVertical ? POSITIONS.VERTICAL_ROCKER : POSITIONS.HORIZONTAL_ROCKER
      );
      break;
    case ONE_SLIDER_ONE_ROCKER:
      dimensions = getSliderRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_SLIDERS:
      dimensions = getSliderSliderDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case ONE_SLIDER_ONE_BUTTON:
      dimensions = getSliderButtonDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_BUTTONS:
      dimensions = getButtonButtonDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_ROCKERS:
      dimensions = getRockerRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case ONE_BUTTON_ONE_ROCKER:
      dimensions = getButtonRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case ONE_SLIDER_TWO_BUTTONS:
      dimensions = getSliderButtonsDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case TWO_BUTTONS_ONE_ROCKER:
      dimensions = getButtonsRockerDimensions(designSizes, insertDetail, type, indexDetail);
      break;
    case FOUR_BUTTONS:
      dimensions = getFourButtonsDimensions(designSizes, type, indexDetail);
      break;
    default:
      console.warn("Unrecognized layout:", layoutName);
      break;
  }

  if (type === EInsertDetailItemType.Icon) {
    const isFunctionalIcon = FUNCTIONAL_ICON_CONDITIONS.some(
      (condition) => condition.name === layoutName && String(indexDetail) === condition.index
    );

    const newIconSize = isFunctionalIcon
      ? FUNCTIONAL_SMALL_ICON_SIZES[iconSizePercentage]
      : CONTROL_ICON_SIZES[iconSizePercentage];
    dimensions = {
      ...dimensions,
      actualHeightMm: newIconSize,
      actualWidthMm: newIconSize,
    };
  }

  if (type === EInsertDetailItemType.Text && fontSize !== EFontPixelSize.STANDARD) {
    dimensions = {
      ...dimensions,
      actualHeightMm: TEXT_SIZES[EFontPixelSize.SMALL],
    };
  }

  return dimensions;
};
