export const PAGE_INITIAL_INDEX = 0;
export const INSERT_DETAILS_SINGLE_ELEMENT = 0;
export const ROWS_PER_PAGE = 5;
export const ROWS_PER_PAGE_DIALOG = 4;
export const NUMBER_OF_SINGLE_COVER = 1;
export const NUMBER_OF_SINGLE_SWITCH = 1;
export const MAX_NUMBER_OF_COVERS = 5;
export const DECIMAL_PLACES = 2;
export const TEN_PERCENT = 10;
export const HUNDRED_PERCENT = 100;
export const NO_ROTATION_0_DEGREE = 0;
export const ROTATION_90_DEGREE = 90;
export const ROTATION_180_DEGREE = 180;
export const ROTATION_270_DEGREE = 270;
export const PROJECT_NAME_LENGTH_IN_WIDGET = 15;
export const MAX_ROWS_FOR_ROCKER = 1;
export const MAX_CHARS_PER_LINE_FOR_ROCKER = 40;
export const MAX_CHARS_PER_LINE_FOR_ROTATED_ROCKER = 9;
export const MAX_CHARS_PER_LINE_FOR_ROTATED_ROCKER_FRAME_62 = 11;
export const MAX_COVER_SETS_NUMBER = 50;
export const MAX_CHARS_NUMBER = 200;
