import { useTheme } from "@emotion/react";
import { Grid, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB } from "../../../constants/Theme";
import { ReactComponent as ReadyAbb } from "../Icons/ready-abb.svg";
import { ReactComponent as ReadyBje } from "../Icons/ready-bje.svg";

const AbbMainSumaryTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          variant="h4"
          fontSize="32px"
          fontWeight={700}
        >
          {t("design_preview.summary.title")}
        </Typography>
        <SvgIcon>
          <ReadyAbb />
        </SvgIcon>
      </div>
      <Typography
        variant="body1"
        fontSize="16px"
        fontWeight={400}
      >
        {t("design_preview.summary.description")}
      </Typography>
    </Grid>
  );
};

const BjeMainSummaryTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="designPreview__mainSummaryTitle">
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          variant="h4"
          fontSize="32px"
          fontWeight={700}
        >
          {t("design_preview.summary.title")}
        </Typography>
        <SvgIcon sx={{ width: "40px", height: "40px" }}>
          <ReadyBje />
        </SvgIcon>
      </div>
      <Typography
        variant="body1"
        fontSize="16px"
        fontWeight={400}
      >
        {t("design_preview.summary.description")}
      </Typography>
    </div>
  );
};

const MainSummaryTitle: React.FC = () => {
  const ftsTheme = useTheme() as FTSTheme;
  return <>{ftsTheme.name == ABB ? <AbbMainSumaryTitle /> : <BjeMainSummaryTitle />}</>;
};

export default MainSummaryTitle;
